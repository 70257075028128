<template>
    <div class="ma-12">
        <form class="form-control">
            <div class="body-control form-header">
                <h3><span>{{ pickedDate }}</span>
                    <span class="float-right">wk {{ weekNr }}</span></h3>
            </div>
            <div width="100%" class="ml-lg-10 ml-md-2 ml-0">
                <table>
                    <tr>
                        <td>
                        </td>
                        <td class="cellData">{{ $t('schedule.sunrise') }}</td>
                        <td class="cellData">{{ $t('schedule.sunset') }}</td>
                        <td :title="powerInfo">{{ $t('schedule.regularConsumption') }} kWh</td>
                        <td>{{ $t('schedule.savings') }} (%)</td>
                        <td>{{ $t('schedule.savings') }} (kg CO2)</td>
                    </tr>
                    <tr>
                        <td class="colorDay">{{ $t('schedule.normal') }}</td>
                        <td class="cellData">{{ sunRiseSet.sunrise.normal }}</td>
                        <td class="cellData">{{ sunRiseSet.sunset.normal }}</td>
                        <td class="cellData">{{ isDataLoaded ? sunRiseSet.power.normal.toFixed(2) : 'calculating' }}</td>
                        <td class="cellData">0</td>
                        <td class="cellData">0</td>
                    </tr>
                    <tr>
                        <td class="colorCivil">{{ $t('schedule.civil') }}</td>
                        <td class="cellData">{{ sunRiseSet.sunrise.civil }}</td>
                        <td class="cellData">{{ sunRiseSet.sunset.civil }}</td>
                        <td class="cellData">{{ isDataLoaded ? sunRiseSet.power.civil.toFixed(2) : 'calculating' }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? (((sunRiseSet.power.normal - sunRiseSet.power.civil) / sunRiseSet.power.normal) * 100).toFixed(2)
                                : 'calculating' }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? ((sunRiseSet.power.normal - sunRiseSet.power.civil) * 0.5).toFixed(0)
                                : 'calculating' }}</td>
                    </tr>
                    <tr>
                        <td class="colorNautical">{{ $t('schedule.nautical') }}</td>
                        <td class="cellData">{{ sunRiseSet.sunrise.nautical }}</td>
                        <td class="cellData">{{ sunRiseSet.sunset.nautical }}</td>
                        <td class="cellData">{{ isDataLoaded ? sunRiseSet.power.nautical.toFixed(2) : 'calculating' }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? (((sunRiseSet.power.normal - sunRiseSet.power.nautical) / sunRiseSet.power.normal) * 100).toFixed(2)
                                : 'calculating'  }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? ((sunRiseSet.power.normal - sunRiseSet.power.nautical) * 0.5).toFixed(0)
                                : 'calculating' }}</td>
                    </tr>
                    <tr>
                        <td class="colorAstronomical">{{ $t('schedule.astronomical') }}</td>
                        <td class="cellData">{{ sunRiseSet.sunrise.astronomical }}</td>
                        <td class="cellData">{{ sunRiseSet.sunset.astronomical }}</td>
                        <td class="cellData">{{ isDataLoaded ? sunRiseSet.power.astronomical.toFixed(2) : 'calculating' }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? (((sunRiseSet.power.normal - sunRiseSet.power.astronomical) / sunRiseSet.power.normal) * 100).toFixed(2)
                                : 'calculating' }}</td>
                        <td class="cellData">{{ isDataLoaded
                                ? ((sunRiseSet.power.normal - sunRiseSet.power.astronomical) * 0.5).toFixed(0)
                                : 'calculating' }}</td>
                    </tr>
                </table>
                <div class="d-flex-row">
                    <div>
                        <label for="inputAantalLampen">{{ $t('schedule.numberOfLamps') }}</label><input id="inputAantalLampen" type="number" v-model="aantalLampen">
                    </div>
                    <div>
                        <label for="inputVermogenPerLamp">{{ $t('schedule.powerPerLamp') }}</label><input id="inputVermogenPerLamp" type="number" v-model="vermogenLamp">W
                    </div>
                    <div>
                        <v-btn :disabled="!isValid1" @click="calculateTimes">{{ $t('main.calculate') }}</v-btn>
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>
import {getWeekNumber, timeConv} from "@/services/dateConverter";
import SunCalc from 'suncalc';


export default {
    name: "DuskSchedule",
    created() {
        this.searchLocation();
        this.calculateTimes()
    },
    props: {
        today: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            isLoaded: false,
            startTime: '',
            endTime: '',
            isValid: false,
            infoMessage: '',
            boxToggle: 'normal',
            sunRiseSet: {
                sunrise: {
                    normal: '',
                    civil: '',
                    nautical: '',
                    astronomical: ''
                },
                sunset: {
                    normal: '',
                    civil: '',
                    nautical: '',
                    astronomical: ''
                },
                power: {
                    normal: '',
                    civil: '',
                    nautical: '',
                    astronomical: ''
                },
            },
            isChecked: {
                normal: true,
                civil: false,
                nautical: false,
                astronomical: false
            },
            aantalLampen: 25000,
            vermogenLamp: 35,
            gpsLocation: {
                lat: 51.4716833,
                lng: 5.6705665,
                type: 'fixed'
            },
        };
    },
    computed: {
        weekNr() {
            return getWeekNumber(new Date(this.pickedDate));
        },
        powerInfo(){
            return `Aangenomen dat er ${this.aantalLampen} lampen branden. ${this.vermogenLamp} W per lamp en een verbruik van ${this.sunRiseSet.power.normal.toFixed(2)} op een dag`;
        },
        eventDate(){
            return this.pickedDate;
        },
        isValid1(){
            return (this.aantalLampen > 0 && this.aantalLampen < 1000000) && (this.vermogenLamp > 0 && this.vermogenLamp < 5000);
        },
        pickedDate() {
            if (this.today) return this.today;
            const now = new Date();
            const [d, m, y] = [now.getDate(), now.getMonth() + 1, now.getFullYear()];
            if (this.$i18n.locale === 'en') return `${y}-${m}-${d}`;
            return `${d}-${m}-${y}`;
        },
        isDataLoaded() {
            return this.isLoaded;
        }
    },
    methods: {
        checkBox(type) {
            return {
                'mdi-checkbox-blank-outline': !this.isChecked[type],
                'mdi-checkbox-marked': this.isChecked[type]
            }
        },
        toggleCheck(type){
            this.isChecked = {
                normal: false,
                civil: false,
                nautical: false,
                astronomical: false
            };
            this.isChecked[type] = true;
        },
        calculateTimes(){
            const mySunCalc = SunCalc.getTimes(new Date(`${this.pickedDate}`), this.gpsLocation.lat, this.gpsLocation.lng, 0);
            const RiseSet = {
                sunrise: {
                    normal: timeConv(mySunCalc.sunrise),
                    civil: timeConv(mySunCalc['dawn']),
                    nautical: timeConv(mySunCalc['nauticalDawn']),
                    astronomical: mySunCalc['nightEnd'].getHours() ? timeConv(mySunCalc['nightEnd']) : "Prev Day"
                },
                sunset: {
                    normal: timeConv(mySunCalc.sunset),
                    civil: timeConv(mySunCalc['dusk']),
                    nautical: timeConv(mySunCalc['nauticalDusk']),
                    astronomical: mySunCalc['night'].getHours() ? timeConv(mySunCalc['night']) : "Next Day"
                },
                power: {
                    normal: 0,
                    civil: 0,
                    nautical: 0,
                    astronomical: 0
                }
            };
            this.sunRiseSet = {...RiseSet};

            const checked = Object.entries(this.isChecked).map((ch) => { if (ch[1] === true) return ch[0]}).filter(x => x);
            this.startTime = this.sunRiseSet.sunrise[checked];
            this.endTime = this.sunRiseSet.sunset[checked];
            this.calculateValues();
        },
        calculateValues(){

            const power = (this.aantalLampen * this.vermogenLamp) / 1000;

            // Normal
            const normalrise = this.sunRiseSet.sunrise.normal.split(":");
            const normalriseDate = new Date(`${this.pickedDate}T${normalrise[0]}:${normalrise[1]}:00`);
            const normalset = this.sunRiseSet.sunset.normal.split(":");
            const normalsetDate = new Date(`${this.pickedDate}T${normalset[0]}:${normalset[1]}:00`)
            const normalburnHours = 24 - ((normalsetDate - normalriseDate) / (1000 * 60 * 60));
            this.sunRiseSet.power.normal = power * normalburnHours;

            // Civil
            const civilrise = this.sunRiseSet.sunrise.civil.split(":");
            const civilriseDate = new Date(`${this.pickedDate}T${civilrise[0]}:${civilrise[1]}:00`);
            const civilset = this.sunRiseSet.sunset.civil.split(":");
            const civilsetDate = new Date(`${this.pickedDate}T${civilset[0]}:${civilset[1]}:00`)
            const civilburnHours = 24 - ((civilsetDate - civilriseDate) / (1000 * 60 * 60));
            this.sunRiseSet.power.civil = power * civilburnHours;

            // Nautical
            const nauticalrise = this.sunRiseSet.sunrise.nautical.split(":");
            const nauticalriseDate = new Date(`${this.pickedDate}T${nauticalrise[0]}:${nauticalrise[1]}:00`);
            const nauticalset = this.sunRiseSet.sunset.nautical.split(":");
            const nauticalsetDate = new Date(`${this.pickedDate}T${nauticalset[0]}:${nauticalset[1]}:00`)
            const nauticalburnHours = 24 - ((nauticalsetDate - nauticalriseDate) / (1000 * 60 * 60));
            this.sunRiseSet.power.nautical = power * nauticalburnHours;

            // Astronomical
            const astronomicalrise = this.sunRiseSet.sunrise.astronomical.split(":");
            const astronomicalriseDate = new Date(`${this.pickedDate}T${astronomicalrise[0]}:${astronomicalrise[1]}:00`);
            const astronomicalset = this.sunRiseSet.sunset.astronomical.split(":");
            const astronomicalsetDate = new Date(`${this.pickedDate}T${astronomicalset[0]}:${astronomicalset[1]}:00`)
            const astronomicalburnHours = 24 - ((astronomicalsetDate - astronomicalriseDate) / (1000 * 60 * 60));
            this.sunRiseSet.power.astronomical = power * astronomicalburnHours;
            this.isLoaded = true;
        },
        searchLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.gpsLocation.lat = position.coords.latitude;
                    this.gpsLocation.lng = position.coords.longitude;
                });
            }
        }
    },
    watch: {
        eventDate(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.calculateTimes();
            }
        },
        isChecked: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.calculateTimes();
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.form-header {
    background-color: #D3D3D3;
    padding: 15px 15px 15px 15px;
}
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
input {
    border: 1px grey solid;
    padding: 5px 10px 5px 10px;
    margin: 20px;
}
.body-control {
    max-width: 100%;
    margin: 10px;
}
.errorMsg {
    color: red;
    border-color: red;
}

table, tr, td {
    font-size: 16px;
    border: 1px solid black;
    padding: 5px;
    margin: 5px;
}
tr {
    height: 36px;
}
.cellData {
    text-align: center;
}
.colorDay {
    background-color: #dbe9ff;
}
.colorCivil {
    background-color: #87a4d3;
}
.colorNautical {
    background-color: #4773bb;
}
.colorAstronomical {
    background-color: #263e66;
    color: white;
}
</style>
