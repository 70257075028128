<template>
    <div>
        <form class="form-control">
            <div class="form-header">
                <h3>Profile <slot name="profile_id">= Unknown =</slot> for IO<slot name="io_id">= Unknown =</slot></h3>
            </div>
            <div class="switch-list">
                <ul>
                    <li v-for="switch1 in tempSwitches" :key="switch1.switch_id">
                        <span class="d-inline-flex align-center"><switch-display-card :switch1="switch1" /></span>
                        <span class="align-content-center float-right"><v-btn class="mt-5" icon small @click="removeSwitch(switch1.switch_id)"><v-icon color="red">mdi-delete</v-icon></v-btn></span>
                    </li>
                </ul>
            </div>
            <div class="d-flex flex-column body-control body-box" v-if="nrOfSwitchOff === 0">
                <div style="width: 100%;" v-if="forceShowSliderStart">
                    <h4 align="center">Light sensitivity (lx) Start</h4>
                    <v-slider
                            style="font-size: 10px; margin-bottom: 2px;"
                            v-model="lightStart"
                            :rules="lightRules"
                            :max="tickLabels.length - 1"
                            step="1"
                            color="green"
                            track-color="red"
                            ticks="always"
                            tick-size="12"
                            :tick-labels="tickLabels"

                    >
                    </v-slider>
                </div>
                <div class="d-flex align-center">
                    <span :class="['super-label', {'shows-slider': showsSliderStart, 'not-shows-slider': !showsSliderStart}]">{{ $t('main.start') }}</span>
                    <input type="text" :placeholder="startPlaceholder" v-model="profileStart" v-if="profileStartType === 'absolute'"/>
                    <input
                            style="width: 224px;"
                            type="number"
                            placeholder="light intensity"
                            :value="tickLabels[lightStart]"
                            v-else-if="(profileStartType === 'sunrise' || profileStartType === 'sunset') && forceShowSliderStart"
                            readonly
                    />
                    <input
                            :placeholder="startPlaceholder"
                            v-model="profileStart"
                            v-else-if="(profileStartType === 'sunrise' || profileStartType === 'sunset') && !forceShowSliderStart"
                            type="text"
                    />
                    <input
                            v-else
                            style="width: 224px;"
                            :value="tickLabels[lightStart]"
                            type="number"
                            placeholder="light intensity"
                            readonly
                    />

                    <div class="pt-6" :style="$i18n.locale === 'en' ? 'width: 130px': 'width: 150px'">
                        <v-select
                                v-model="profileStartType"
                                full-width
                                :items="inputItems"
                                :label="$t('schedule.switchType')"
                                outlined
                                background-color="white"
                                color="blue"
                                item-color="red"
                                dense
                                @change.self="clearSwitches('start')"
                        ></v-select>
                    </div>
                    <div class="inputInfoBox">
                        <h6>{{ inputInfoTranslation('start') }}</h6>
                    </div>
                    <div class="mx-4" v-if="profileStartType === 'sunrise' || profileStartType === 'sunset'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    :color="forceShowSliderStart ? 'yellow' : 'grey'"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click.prevent="forceShowSliderStart = !forceShowSliderStart"
                                >
                                    <v-icon small>mdi-lightbulb</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a light sensitivity restriction for turning on</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column body-control body-box" v-if="nrOfSwitchOff === 0">
                <div style="width: 100%;" v-if="forceShowSliderEnd">
                    <h4 align="center">Light sensitivity (lx) End</h4>
                    <v-slider
                            style="font-size: 10px; margin-bottom: 2px;"
                            v-model="lightEnd"
                            :rules="lightRules"
                            :max="tickLabels.length - 1"
                            step="1"
                            color="green"
                            track-color="red"
                            ticks="always"
                            tick-size="12"
                            :tick-labels="tickLabels"

                    >
                    </v-slider>
                </div>
                <div class="d-flex align-center">
                    <span :class="['super-label', {'shows-slider': showsSliderEnd, 'not-shows-slider': !showsSliderEnd}]">{{ $t('main.end') }}</span>
                    <input type="text" :placeholder="endPlaceholder" v-model="profileEnd" v-if="profileEndType === 'absolute'"/>
                    <input
                            v-else-if="(profileEndType === 'sunrise' || profileEndType === 'sunset') && forceShowSliderEnd"
                            style="width: 224px;"
                            :value="tickLabels[lightEnd]"
                            type="number"
                            placeholder="Light Intensity"
                            readonly
                    />
                    <input
                            :placeholder="endPlaceholder"
                            v-model="profileEnd"
                            v-else-if="(profileEndType === 'sunrise' || profileEndType === 'sunset') && !forceShowSliderEnd"
                            type="text"
                    />
                    <input
                            v-else
                            style="width: 224px;"
                            :value="tickLabels[lightEnd]"
                            type="number"
                            placeholder="light intensity"
                            readonly
                    />
                    <div class="pt-6" :style="$i18n.locale === 'en' ? 'width: 130px': 'width: 150px'">
                        <v-select
                                v-model="profileEndType"
                                full-width
                                :items="inputItems"
                                :label="$t('schedule.switchType')"
                                outlined
                                background-color="white"
                                color="blue"
                                item-color="red"
                                dense
                                @change.self="clearSwitches('end')"
                        ></v-select>
                    </div>
                    <div class="inputInfoBox">
                        <h6>{{ inputInfoTranslation('end') }}</h6>
                    </div>
                    <div class="mx-4" v-if="profileEndType === 'sunrise' || profileEndType === 'sunset'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        small
                                        :color="forceShowSliderEnd ? 'yellow' : 'grey'"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent="forceShowSliderEnd = !forceShowSliderEnd"
                                >
                                    <v-icon small>mdi-lightbulb</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a light sensitivity restriction for turning off</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-space-between">

                <!-- 'SWITCH OFF' BUTTON -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ma-3"
                                color="warning"
                                @click="switchOff"
                                v-show="nrOfSwitchOff === 0 && tempSwitches.length === 0"
                        >SWITCH OFF</v-btn>
                    </template>
                    <span>TOOLTIP SWITCH OFF</span>
                </v-tooltip>

                <!-- 'ADD SWITCH' BUTTON -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ma-3"
                                color="success"
                                :disabled="!validSwitch || tempSwitches.length >= MAX_SWITCHES"
                                @click="addSwitch"
                        >{{ $t('main.addSwitch') }}</v-btn>
                    </template>
                    <span>TOOLTIP ADD SWITCH</span>
                </v-tooltip>

                <!-- 'CLEAR SWITCHES' BUTTON -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ma-3"
                                color="error"
                                @click="clearSwitches()"
                                :disabled="tempSwitches.length === 0"
                        >{{ $t('main.clearSwitch') }}</v-btn>
                    </template>
                    <span>TOOLTIP CLEAR SWITCHES</span>
                </v-tooltip>
            </div>
            <div class="week-control" v-if="showWeekChooser">
                <v-row class="ma-0">
                    <v-col class="week-border" @click="checkBox('0')">
                        <input id="toggleMonday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="0"/>
                    </v-col>
                    <v-col class="week-border" @click="checkBox('1')">
                        <input id="toggleTuesday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="1" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('2')">
                        <input id="toggleMWednesday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="2" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('3')">
                        <input id="toggleThursday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="3" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('4')">
                        <input id="toggleFriday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="4" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('5')">
                        <input id="toggleSaturday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="5" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('6')">
                        <input id="toggleSunday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="6" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('ALL')">
                        <input id="toggleWeek" v-model="toggleAll" name="toggleWeek" type="checkbox" value="ALL" />
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col class="week-border" @click="checkBox('0')">{{ $t('schedule.weekdays.mon') }}</v-col>
                    <v-col class="week-border" @click="checkBox('1')">{{ $t('schedule.weekdays.tue') }}</v-col>
                    <v-col class="week-border" @click="checkBox('2')">{{ $t('schedule.weekdays.wed') }}</v-col>
                    <v-col class="week-border" @click="checkBox('3')">{{ $t('schedule.weekdays.thu') }}</v-col>
                    <v-col class="week-border" @click="checkBox('4')">{{ $t('schedule.weekdays.fri') }}</v-col>
                    <v-col class="week-border" @click="checkBox('5')">{{ $t('schedule.weekdays.sat') }}</v-col>
                    <v-col class="week-border" @click="checkBox('6')">{{ $t('schedule.weekdays.sun') }}</v-col>
                    <v-col class="week-border" @click="checkBox('ALL')"><strong>{{ $t('schedule.weekdays.all') }}</strong></v-col>
                </v-row>
            </div>
            <div v-if="showWeekChooser">
                <v-row cols="12" class="d-flex justify-space-between align-center">
                    <v-col class="col-12 d-flex flex-row justify-start align-center ms-2">
                        <label for="recurringInput">{{ $t('main.recurring') }}</label><input type="radio" name="range" id="recurringInput" v-model="recurring" value="recurring"/>
                        <label for="rangeInput">{{ $t('main.range') }}</label><input type="radio" name="range" id="rangeInput" v-model="recurring" value="range"/>
                        <label for="rangeWithYear">{{ $t('main.rangeWithYear') }}</label><input type="radio" name="range" id="rangeWithYear" v-model="recurring" value="year" />
                        <div @click="infoShow = !infoShow ">
                            <v-icon class="mx-4" color="green">mdi-comment-question-outline</v-icon>
                        </div>
                    </v-col>
                    <v-col>
                        <DateInputInfo
                                :title="info[calcRangeIndex].title"
                                :text="info[calcRangeIndex].text"
                                v-if="infoShow"
                                :key="info[calcRangeIndex].id"
                        />
                    </v-col>
                </v-row>
                <v-row cols="12" class="d-flex justify-space-between align-center">
                   <v-col class="col-12" v-if="recurring === 'range'">
                        <label for="inputStartDate"></label>{{ $t('schedule.startDate') }} : <input class="input2" type="text" id="inputStartDate" :placeholder="i18nPlaceholder" v-model="range.startDate"/> -
                        <label for="inputDateEnd">{{ $t('schedule.endDate') }} : </label><input class="input2" type="text" id="inputDateEnd" :placeholder="i18nPlaceholder" v-model="range.endDate" />

                    </v-col>
                    <v-col class="col-12" v-if="recurring === 'year'">
                        <label for="inputStartDate"></label>{{ $t('schedule.startDate') }} : <input class="input3" type="text" id="inputStartDate" :placeholder="i18nPlaceholder" v-model="range.startDate"/> -
                        <label for="inputDateEnd">{{ $t('schedule.endDate') }} : </label><input class="input3" type="text" id="inputDateEnd" :placeholder="i18nPlaceholder" v-model="range.endDate" />
                    </v-col>

                </v-row>
                <v-row cols="12">
                    <v-col class="col-12" v-if="recurring === 'range'">
                        <div class="errorMsg" v-if="!validStartDate">
                            {{ $t('schedule.validStartDate') }}
                        </div>
                        <div class="errorMsg" v-if="!validEndDate">
                            {{ $t('schedule.validEndDate') }}
                        </div>
                    </v-col>
                    <v-col class="col-12" v-else-if="recurring === 'year'">
                        <div class="errorMsg" v-if="!validStartDate">
                            {{ $t('schedule.validStartDateYear') }}
                        </div>
                        <div class="errorMsg" v-if="!validEndDate">
                            {{ $t('schedule.validEndDateYear') }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-btn class="ma-4" color="warning" @click="cancelAction" >{{ $t('main.cancel') }}</v-btn>
                <v-btn class="ma-4" color="success" :disabled="!validProfile" @click="addOrSaveProfile(ioInputType)" v-if="ioInputType === 'new'">{{ $t('main.addProfile') }}</v-btn>
                <v-btn class="ma-4" color="success" :disabled="!validProfile" @click="addOrSaveProfile(ioInputType)" v-if="ioInputType === 'edit'">{{ $t('main.saveProfile')}}</v-btn>
            </div>
        </form>
        <ConfirmDialog ref="confirmDialogue" ></ConfirmDialog>
    </div>
</template>

<script>
import SwitchDisplayCard from "@/components/Schedule/SwitchDisplayCard";
import {offsetConv, paddedDateConv, timeStrConv} from "@/services/dateConverter";
import ConfirmDialog from "@/components/Standard/ConfirmDialog";
import DateInputInfo from "@/components/Schedule/DateInputInfo.vue";

const reTime = /(^(2[0-3]|[01]?[0-9])([:.]?)([0-5]?[0-9])$)/
const reOffset = /^([+-]?\d{0,4}[,.]?\d{0,2})([HhMm]?)$/

const reDateEn = /(^(1[0-2]|0?[1-9])[/.-](3[01]|[12][0-9]|0?[1-9])$)/
const reDateEnYear = /(^([12][0-9]{3}|[0-9]{2})[/.-](1[0-2]|0?[1-9])[/.-](3[01]|[12][0-9]|0?[1-9])$)/
const reDateNl = /(^(3[01]|[12][0-9]|0?[1-9])[/.-](1[0-2]|0?[1-9])$)/
const reDateNlYear = /(^(3[01]|[12][0-9]|0?[1-9])[/.-](1[0-2]|0?[1-9])[/.-]([12][0-9]{3}|[0-9]{2})$)/

const lichtLevels = [
    {0.0001: "Bewolkte nacht zonder maan"},
    {0.001: "Nieuwe maan zonder wolken"},
    {0.01: "Kwartier maan"},
    {0.1: "Volle maan"},
    {1: "Donkere schemering"},
    {10: "Schemering"},
    {100: "Erg donkere dag"},
    {1000: "Bewolkte dag"},
    {10000: "Daglicht, direct zonlicht"},
    {50000: "Lichte dag"},
    {100000: "Zonlicht"},
]

export default {
    name: "ProfileInputCard",
    components: {
        DateInputInfo,
        SwitchDisplayCard,
        ConfirmDialog
    },
    emits: ['save-profile'],
    props: {
        io_data: {
            type: Object,
            required: false,
            default: null
        },
        io_id: {
            type: Number,
            required: false,
            default: -1
        }
    },
    created() {
        if (this.io_data) {
            this.ioInputType = 'edit';
            this.oldLength = this.io_data.switches.length;
            this.populateData();
        } else {
            this.ioInputType = 'new';
        }
    },
    data() {
        return {
            infoShow: false,
            MAX_SWITCHES: 4,
            tickLabels: ['0.0001','0.001','0.01','0.1','1','10', '20', '30', '40', '50', '100','1000','10000', '50000', '100000'],
            tempSwitches: [],
            lightStart: 0,
            lightEnd: 14,
            lightRules: [
                v => !!v || 'Value is required',
                v => (v && v >= 0.0001) || 'Value must be greater than 0.0001',
                v => (v && v <= 100000) || 'Value must be less than 100000'
            ],
            startTime: '',
            endTime: '',
            profileStart: '',
            profileEnd: '',
            profileStartType: 'absolute',
            profileEndType: 'absolute',
            // inputInfo: {
            //     'absolute': this.$t('tooltip.absoluteInfo'),
            //     'sunrise': this.$t('tooltip.sunriseInfo'),
            //     'sunset': this.$t('tooltip.sunsetInfo'),
            // },
            inputItems: [
                {
                    id: 1,
                    text: this.$t('schedule.absolute'),
                    value: 'absolute'
                },
                {
                    id: 2,
                    text: this.$t('schedule.sunrise'),
                    value: 'sunrise'
                },
                {
                    id: 3,
                    text: this.$t('schedule.sunset'),
                    value: 'sunset'
                },
            ],
            toggleWeek: [],
            toggleAll: false,
            recurring: 'recurring',
            range: {
                startDate: '',
                endDate: ''
            },
            ioInputType: 'new',
            switchCount: 0,
            forceShowSliderStart: false,
            forceShowSliderEnd: false,
        };
    },
    computed: {
        info() {
            return [
                {
                    id: 1,
                    title: this.$t('schedule.dateInfoTitle'),
                    text: this.$t('schedule.dateInfoText'),
                },
                {
                    id: 2,
                    title: this.$t('schedule.dateInfoRangeTitle'),
                    text: this.$t('schedule.dateInfoRangeText'),
                },
                {
                    id: 3,
                    title: this.$t('schedule.dateInfoRangeYearTitle'),
                    text: this.$t('schedule.dateInfoRangeYearText'),
                }
            ]
        },
        calcRangeIndex(){
            if (this.recurring === 'range') {
                return 1;
            } else if (this.recurring === 'year') {
                return 2;
            }
            return 0;
        },
        showsSliderStart(){
            return this.profileStartType === 'photocell';
        },
        showsSliderEnd(){
            return this.profileEndType === 'photocell';
        },
        i18nPlaceholder(){
            // type = short -> MM-DD
            // type = long -> YY-MM-DD
            let result;
            if (this.recurring === 'year') {
                if (this.$i18n.locale === 'en') {
                    result = "YYYY-MM-DD";
                } else {
                    result = "DD-MM-YYYY";
                }
            } else if (this.recurring === 'range') {
                if (this.$i18n.locale === 'en') {
                    result = "MM-DD";
                } else result = "DD-MM";
            }
            return result;
        },
        validSwitch(){
            let valid = (this.nrOfSwitchOff === 0  || (this.nrOfSwitchOff > 0 && (this.recurring === 'year' || this.recurring === 'range')));
            return valid && (this.startValid && this.endValid);
        },
        validProfile(){
            let valid = true;
            valid = valid && (this.toggleWeek.length > 0);
            if (this.nrOfSwitchOff > 0) {
                valid = valid && (this.recurring === 'year' || this.recurring === 'range');
            }
            if (this.recurring === 'range') {
                valid = valid && (this.validStartDate && this.validEndDate);
            }
            return valid && (this.tempSwitches.length > 0);
        },
        validStartDate() {
            let isValid = false;
            const locale = this.$i18n.locale || 'en';
            if (locale === 'en' && this.recurring === 'range'){
                isValid = reDateEn.test(this.range.startDate);
            } else if (locale === 'en' && this.recurring === 'year') {
                isValid = reDateEnYear.test(this.range.startDate) || (reDateEn.test(this.range.startDate) && reDateEnYear.test(this.range.endDate));
            } else if (locale === 'nl' && this.recurring === 'range'){
                isValid = reDateNl.test(this.range.startDate);
            } else if (locale === 'nl' && this.recurring === 'year') {
                isValid = reDateNlYear.test(this.range.startDate) || (reDateNl.test(this.range.startDate) && reDateNlYear.test(this.range.endDate));
            }
            return isValid;
        },
        validEndDate() {
            let isValid = false;
            const locale = this.$i18n.locale || 'en';
            if (locale === 'en' && this.recurring === 'range'){
                isValid = reDateEn.test(this.range.endDate);
            } else if (locale === 'en' && this.recurring === 'year') {
                isValid = reDateEnYear.test(this.range.endDate) || (reDateEnYear.test(this.range.startDate) && reDateEn.test(this.range.endDate));
            } else if (locale === 'nl' && this.recurring === 'range'){
                isValid = reDateNl.test(this.range.endDate);
            } else if (locale === 'nl' && this.recurring === 'year') {
                isValid = reDateNlYear.test(this.range.endDate) || (reDateNl.test(this.range.startDate) && reDateNlYear.test(this.range.endDate));
            }
            // TODO check if end date is AFTER or EQUAL TO start date
            return isValid;
        },
        startValid() {
            let valid = false;
            if (this.profileStartType === 'absolute') {
                valid = reTime.test(this.profileStart) && this.profileStart.length > 2;
            } else if (this.profileStartType === 'photocell') {
                valid = parseFloat(this.tickLabels[this.lightStart]) > 0 && parseFloat(this.tickLabels[this.lightStart]) <= 100000;
            } else {
                // Can be an offset or a lightsensitivity
                valid = reOffset.test(this.profileStart) && this.profileStart.length > 0;
            }
            return valid;
        },
        endValid() {
            let valid = false;
            if (this.profileEndType === 'absolute'){
                valid = reTime.test(this.profileEnd) && this.profileEnd.length > 2;
            } else if (this.profileStartType === 'photocell') {
                valid = parseFloat(this.tickLabels[this.lightEnd]) > 0 && parseFloat(this.tickLabels[this.lightEnd]) <= 100000;
            } else {
                // Can be an offset or a lightsensitivity
                valid = reOffset.test(this.profileEnd) && this.profileEnd.length > 0;
            }
            return valid;
        },
        startPlaceholder() {
            let ph;
            if (this.profileStartType === 'absolute'){
                ph = 'schedule.startPlaceholderAbs';
            }
            return this.$t(ph);
        },
        endPlaceholder() {
            let ph;
            if (this.profileEndType === 'absolute'){
                ph = 'schedule.endPlaceholderAbs';
            }
            return this.$t(ph);
        },
        showWeekChooser(){
            if (this.ioInputType === 'edit'){
                return true;
            } else {
                return this.tempSwitches.length > 0;
            }
        },
        nrOfSwitchOff(){
            return this.tempSwitches.map((sw) => sw.on.time === sw.off.time && sw.on.kind === 'absolute').filter(x => x).length;
        }
    },
    methods: {
        cancelAction(){
            this.$emit('save-profile', 'cancel', null);
            this.clearProfile();
        },
        populateData() {
            let id = 0;
            for (let switch1 of this.io_data.switches){
                const switch2 = {
                    switch_id: id++,
                    off: switch1.off,
                    on: switch1.on,
                }
                this.tempSwitches.push(switch2);
            }
            this.toggleWeek = [...this.convertWeekNum(this.io_data.days)];
            // Check if the range is a recurring range or a range with a year
            // Do this for both startdate and enddate
            // if EITHER one is a range with a year, set the recurring to 'year'
            // otherwise use 'range'
            if (this.io_data.startDate) {
                // check the length of the dates :
                // - 2 -> range
                // - 3 -> year
                const startDateLength = this.io_data.startDate.split('-').length;
                const endDateLength = this.io_data.endDate.split('-').length;

                if(startDateLength === 3 || endDateLength === 3){
                    this.recurring = 'year';
                    this.range.startDate = this.io_data.startDate;
                    this.range.endDate = this.io_data.endDate;
                } else {
                    this.recurring = 'range';
                    this.range.startDate = this.io_data.startDate;
                    this.range.endDate = this.io_data.endDate;
                }

            }
        },
        addSwitch(){
            let switchOn;
            let switchOff;
            const switchId = this.tempSwitches.length;
            if (this.profileStartType === 'absolute') {
                switchOn = {
                    kind: 'Absolute',
                    time: timeStrConv(this.profileStart)
                };
            } else  {
                // Can be an offset or a light sensitivity value
                if (!this.forceShowSliderStart) {
                    switchOn = {
                        kind: this.capitalizeString(this.profileStartType) ,
                        offset: offsetConv(`${this.profileStart}`, 1)
                    };
                } else {
                    switchOn = {
                        kind: this.capitalizeString(this.profileStartType) + 'LightAssisted',
                        level: parseFloat(this.tickLabels[this.lightStart])
                    };
                }
            }
            if (this.profileEndType === 'absolute') {
                switchOff = {
                    kind: 'Absolute',
                    time: timeStrConv(this.profileEnd)
                };
            }  else {
                // Can be an offset or a light sensitivity value
                if(!this.forceShowSliderEnd) {
                    switchOff = {
                        kind: this.capitalizeString(this.profileEndType),
                        offset: offsetConv(`${this.profileEnd}`, 1)
                    };
                } else {
                    switchOff = {
                        kind: this.capitalizeString(this.profileEndType) + 'LightAssisted',
                        level: parseFloat(this.tickLabels[this.lightEnd])
                    };
                }
            }

            this.tempSwitches.push(
            {
                switch_id: switchId,
                on: {
                    ...switchOn,
                },
                off: {
                    ...switchOff,
                }
            });
            this.switchCount++;
            this.profileStart = '';
            this.profileEnd = '';
            this.forceShowSliderStart = false;
            this.forceShowSliderEnd = false;
            this.profileStartType = 'absolute';
            this.profileEndType = 'absolute';
        },
        addOrSaveProfile(inpType) {
            // type is 'new' or 'edit'
            // 'new' -> profile to be created
            // 'edit' -> profile to be overwritten
            const loc = this.$i18n.locale;
            let localeStartDate, localeEndDate;
            if (loc === 'nl') {
                localeStartDate = this.range.startDate ? paddedDateConv(this.range.startDate, loc) : '';
                localeEndDate = this.range.endDate ? paddedDateConv(this.range.endDate, loc) : '';
            } else {
                localeStartDate = this.range.startDate ? paddedDateConv(this.range.startDate, loc) : '';
                localeEndDate = this.range.endDate ? paddedDateConv(this.range.endDate, loc) : '';
            }
            const switches = {
                profile_id: this.io_data ? this.io_data.profile_id : -1,
                io_id: this.io_id,
                switches: [...this.tempSwitches],
                days: this.convertWeekDay(this.toggleWeek),
                startDate: localeStartDate,
                endDate: localeEndDate
            }
            this.$emit('save-profile' ,inpType , switches);
            this.clearProfile();
        },
        clearSwitches(switchType = null) {
            if (!switchType){
                const oldLength = this.tempSwitches.length;
                this.tempSwitches.splice(0, oldLength);
                this.profileStart = '';
                this.profileEnd = '';
                this.profileStartType = 'absolute';
                this.profileEndType = 'absolute';
                this.forceShowSliderStart = false;
                this.forceShowSliderEnd = false;
            } else if (switchType === 'start') {
                // Only clear the start switch data
                this.profileStart = '';
            } else if (switchType === 'end'){
                // Only clear the end switch data
                this.profileEnd = '';
            }
        },
        clearProfile() {
            this.clearSwitches();
            this.toggleWeek = [];
            this.toggleAll = false;
            this.range = { startDate: '', endDate: ''};
            this.recurring = 'recurring';
            this.ioInputType = 'new';
        },
        async removeSwitch(switchId) {
            const ok = await this.$refs.confirmDialogue.show({
                title: this.$t('main.deleteSwitch'),
                message: this.$t('main.deleteSwitchMessage'),
                okButton: this.$t('main.remove'),
            });
            if (ok){
                const switchIndex = this.tempSwitches.findIndex(elem => elem.switch_id === switchId);
                this.tempSwitches.splice(switchIndex, 1);
            }

        },
        convertWeekDay(arrayOfNumbers) {
            let arrayOfDays = [];
            arrayOfNumbers.forEach((num) => {
                switch(num) {
                    case '0':
                        arrayOfDays.push('mon');
                        break;
                    case '1':
                        arrayOfDays.push('tue');
                        break;
                    case '2':
                        arrayOfDays.push('wed');
                        break;
                    case '3':
                        arrayOfDays.push('thu');
                        break;
                    case '4':
                        arrayOfDays.push('fri');
                        break;
                    case '5':
                        arrayOfDays.push('sat');
                        break;
                    case '6':
                        arrayOfDays.push('sun');
                        break;
                }
            });
            return arrayOfDays;
        },
        convertWeekNum(arrayOfDays) {
            let arrayOfNums = [];
            arrayOfDays.forEach((day) => {
                switch(day) {
                    case 'mon':
                        arrayOfNums.push('0');
                        break;
                    case 'tue':
                        arrayOfNums.push('1');
                        break;
                    case 'wed':
                        arrayOfNums.push('2');
                        break;
                    case 'thu':
                        arrayOfNums.push('3');
                        break;
                    case 'fri':
                        arrayOfNums.push('4');
                        break;
                    case 'sat':
                        arrayOfNums.push('5');
                        break;
                    case 'sun':
                        arrayOfNums.push('6');
                        break;
                }
            });
            return arrayOfNums;
        },
        checkBox(event) {
            if (event === "ALL"){
                this.toggleAll = !this.toggleAll;
            } else {
                const idx = this.toggleWeek.indexOf(event);
                if (idx !== -1) this.toggleWeek.splice(idx, 1);
                else this.toggleWeek.push(event);
            }
        },
        capitalizeString(string1){
            const strLen = string1.length;
            return string1.charAt(0).toUpperCase() + string1.substring(1, strLen);
        },
        inputInfoTranslation(type){
            let inputInf;
            if (type === 'start') {
                inputInf = this.profileStartType;
            } else {
                inputInf = this.profileEndType;
            }
            switch (inputInf) {
                case 'absolute':
                    return this.$t('tooltip.absoluteInfo');
                case 'sunrise':
                    if(this.forceShowSliderStart || this.forceShowSliderEnd) return this.$t('tooltip.sunriseLightInfo');
                    return this.$t('tooltip.sunriseInfo');
                case 'sunset':
                    if(this.forceShowSliderStart || this.forceShowSliderEnd) return this.$t('tooltip.sunsetLightInfo');
                    return this.$t('tooltip.sunsetInfo');
                default:
                    return '';
            }
        },
        switchOff(){
            const switchId = this.tempSwitches.length;
            this.tempSwitches.push(
                {
                    switch_id: switchId,
                    on: {
                        kind: 'Absolute',
                        time: '00:00',
                    },
                    off: {
                        kind: 'Absolute',
                        time: '00:00',
                    }
                });
        }
    },
    watch: {
        toggleAll(value){
            if (value) {
                this.toggleWeek = ["1", "2", "3", "4", "5", "6", "0"];
            } else {
                this.toggleWeek = [];
            }
        },
        profileStartType(newV, oldV) {
            if(oldV !== newV){
                if (newV !== 'absolute')
                {
                    if(!this.profileStart)
                    this.profileStart = '0';
                } else {
                    this.profileStart = '';
                }
            }
        },
        profileEndType(newV, oldV) {
            if(oldV !== newV){
                if (newV !== 'absolute')
                {
                    if(!this.profileEnd)
                    this.profileEnd = '0';
                } else {
                    this.profileEnd = '';
                }
            }
        },

    },
}
</script>

<style>
.v-slider__tick-label {
    margin-top: 4px !important;
}
</style>
<style scoped>
.errorMsg {
    color: red;
    border-color: red;
}
.form-header {
    background-color: #D3D3D3;
    padding: 15px 15px 15px 15px;
}
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
input {
    border: 1px grey solid;
    padding: 5px 10px 5px 10px;
    margin: 20px;
}
.input2 {
    border: 1px grey solid;
    padding: 0 5px 0 5px;
    margin: 0;
    width: 80px;
}
.input3 {
    border: 1px grey solid;
    padding: 0 5px 0 5px;
    margin: 0;
    width: 120px;
}
.body-control {
    max-width: 100%;
    margin: 10px;
}
.body-box {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 10px 2px 10px 2px;
    margin: 10px 0 10px 0;
}
.week-control {
    margin: 0 5px;
    text-align: center;
}
.week-border {
    border: 1px solid #e3e3e3;
    border-collapse: collapse;
    cursor: pointer;
}
.inputInfoBox {
    width: 200px;
    border: red solid 1px;
    margin: 0 0 0 15px;
    padding: 2px;
}
.label-style {
    width: 60px;
    display: block;
}
.super-label {
    display: inline-block;
    position: absolute;
    margin-left: 0.5em;
    padding: 0 0.5em 0 0.5em;
    font-weight: bold;
    font-size: 1.2em;
    color: dimgray;
    background: white;
    border: 1px solid gray;
    border-radius: 5px;
}
.not-shows-slider {
    margin-top: -5em;
}
.shows-slider {
    margin-top: -13.3em;
}
</style>
