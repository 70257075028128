<template>
    <div v-if="isAuthenticated">
        <v-sheet height="650" outlined>
            <div class="d-flex flex-row align-center" v-if="singleSchedule">
                <div>
                    <v-card-title>"{{ currentSchedule['name'] }}"</v-card-title>
                </div>

                <div class="d-flex flex-row mr-8" v-if="Object.keys(showIo).length > 0">
                    <v-avatar
                            v-for="schedule in currentSchedule.schedules"
                            :key="schedule.io_id"
                            :style="{cursor: 'pointer'}"
                            class="px-4 mx-4"
                            :color="calcColor(schedule.io_id)"
                            :ref="schedule.io_id"
                            @click="checkChangeButton"
                    >
                        <span class="white--text text-h6 px-3">{{ schedule.io_id }}</span>
                    </v-avatar>

                </div>
                <div>
                    <div class="mx-4">
                        {{ $t('schedule.showRiseSet') }} <span>({{ $t('schedule.rise') }}: {{ getSunrise }} -  {{ $t('schedule.set') }}: {{ getSunset }} )</span>
                    </div>
                </div>
            </div>
            <v-calendar
                    ref="weekCalendar"
                    :now="today ? `${today} 00:00:00` : undefined"
                    v-model="todayShadow"
                    :event-color="getEventColor"
                    :event-ripple="false"
                    :events="newEvents"
                    :weekdays="weekDays"
                    color="accent"
                    type="week"
                    @click:event="showEvent"
                    @click:date="$emit('openDayView', {day: todayShadow})"
                    :short-intervals="false"
                    interval-height="20"
                    :locale="$i18n.locale"
            >
                <template v-slot:event="{ event }">
                    <div>
                        <div class="text-center"><strong>{{ event.name }}</strong></div>
                        <div class="text-center" v-if="eventLength(event) > 2">IO : {{ event.io_id }}</div>
                    </div>
                </template>
                <template v-if="true" v-slot:day-body="{ timeToY }">

                    <div
                            :style="{ top: timeToY(getSunrise ? getSunrise : '0:00') + 'px' }"
                            class="v-current-time"
                    ></div>

                    <div

                            :style="{ top: timeToY(getSunset ? getSunset : '23.59') + 'px'}"
                            class="v-current-time"
                    ></div>
                </template>
            </v-calendar>

            <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                    top
            >
                <v-card
                        color="grey lighten-4"
                        min-width="350px"
                >
                    <v-toolbar
                            :color="selectedEvent.color"
                            dark
                    >
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    </v-toolbar>
                    <v-card-title class="mx-auto">
                        {{ $t('menu.buttons.schedule') }} UID : {{ selectedEvent.schedule_uid }}
                    </v-card-title>
                    <v-card-subtitle>
                        <div>
                            IO : {{ selectedEvent.io_id }}
                        </div>
                        <div>
                            {{ $t('main.profile') }} : {{ selectedEvent.profile_id }}
                        </div>
                    </v-card-subtitle>
                    <switch-profile-card :profile="selectedEvent" :switch-number="parseInt(selectedEvent.switch_id - 1)" :scheduleEvent="true" /> <!-- was :profile="selectedEvent" -->
                </v-card>
            </v-menu>
        </v-sheet>
        <div v-if="currentSchedule">
            <v-btn color="info" @click="sendData" :disabled="!hasSchedule">{{ $t('schedule.editSchedule') }}</v-btn>
        </div>
    </div>
</template>

<script>
import SwitchProfileCard from "@/components/Schedule/SwitchProfileCard.vue";
import {getWeekNumber, timeConv} from "@/services/dateConverter";
import {mapGetters} from "vuex";
import {arrayToString, colors} from "@/services/mainHelpers";

export default {
    name: "WeekCalendar",
    components: {SwitchProfileCard},
    props: {
        today: {
            type: String,
            required: true,
        },
        scheduleProfile: {
            type: Object,
            required: false,
            default: () => {}
        },
        events: {
            type: Array,
            required: false,
            default: () => [],
        }
    },
    async mounted() {
        this.$refs.weekCalendar.checkChange();
        this.init();
    },
    data(){
        return {
            weekDays: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            thisWeek: {
                start: '',
                end: ''
            },
            ready: false,
            totalHoursWeek: 0,
            toggle: false,
            selectedOpen: false,
            selectedElement: null,
            selectedEvent: {},
            showIo: {
                // 'out.1': {
                //     id: 0,
                //     external: 'out.1',
                //     color: 'pink',
                //     show: true
                // },
            },
            tempSchedule: null,
            newEvents: [],
            todayShadow: null,
        };
    },
    computed: {
        ...mapGetters({singleSchedule: 'sched/getSingleSchedule', gpsLocation: 'sched/getGpsLocation',
            sunriseset: 'sched/getSunCalc', isAuthenticated: 'auth/isAuthenticated'}),
        cal() {
            return this.ready ? this.$refs.calendar2 : null;
        },
        getSunrise(){
            return this.sunriseset.sunrise;
        },
        getSunset(){
            return this.sunriseset.sunset;
        },
        getWeekNumber(){
            return getWeekNumber(this.today);
        },
        currentSchedule() {
            return this.scheduleProfile ? this.scheduleProfile : 'No schedule selected';
        },
        hasSchedule(){
            return Object.keys(this.currentSchedule).length > 0;
        },

    },
    methods: {
        init(){
            if(this.$store.getters['auth/StateActiveOrganisation']){
                this.todayShadow = this.today;

                if (this.scheduleProfile) {
                    this.generateIoObjects();
                    this.showEventsWithIos();
                }
                else {
                    if (this.$route.query.schedule_id) {
                        this.$store.dispatch('sched/searchSchedule', {
                            schedule_id: this.$route.query.schedule_id,
                            schedule_version: this.$route.query.schedule_version
                        });
                        this.generateIoObjects();
                        this.showEventsWithIos();
                    } else {
                        // Make no IO avatars
                        Object.keys(this.showIo).forEach((io) => this.showIo[io].show = false);
                    }
                    this.createWeekInfo2();
                }
            } else {
                Object.keys(this.showIo).forEach((io) => this.showIo[io].show === false);
            }
            this.ready = true;
        },
        eventLength(event){
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            const length = (endDate - startDate) / (1000 * 60 * 60); // length in hours
            return Math.floor(length);
        },
        sendData() {
            const scheduleObject = {
                schedule_id: this.currentSchedule.id,
                schedule_version: this.currentSchedule.versionId,
                name: this.currentSchedule.scheduleName,
                io_id: 0,
                profile_id: 0,
                scheduleInputType: 'edit'
            };
            this.$router.push({
                name: 'schedNew',
                params: { crudType: scheduleObject.scheduleInputType },
                query: { schedule_id: `${scheduleObject.schedule_id}`,
                    schedule_version: `${scheduleObject.schedule_version}`,
                    io_id: `${scheduleObject.io_id}`,
                    profile_id: `${scheduleObject.profile_id}`,
                    name: scheduleObject.name }});
        },
        checkChangeButton(event) {
            if (this.$store.getters['auth/StateActiveOrganisation']){
                const ionr = event.target.textContent.toLowerCase();
                this.showIo[ionr].show = !this.showIo[ionr].show;
                this.showEventsWithIos();
            }
        },
        getEventColor(event) {
            return event.color;
        },
        showEvent({nativeEvent, event}){
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                this.selectedOpen = true;
            };
            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation();
        },
        generateIoObjects() {
            this.scheduleProfile.schedules.forEach((schedule) => {
                const io_id = schedule.io_id;
                const id = parseInt(io_id.split(".")[1], 10) - 1;
                this.showIo[io_id] = {
                    id: id,
                    external: schedule.io_id,
                    color: colors[id],
                    show: true
                };
            });
        },
        calcColor(io_id){
            if (io_id){
                const id = Object.keys(this.showIo).find((el) => el === io_id);
                const idx = this.showIo[id].id;
                return this.showIo[id].show ? colors[idx] : colors[21]  // is gray
            }
            return colors[21];  // is gray
        },
        showEventsWithIos(){
            let newEvents = [];
            const whichIos = Object.keys(this.showIo).map((io) => {if (this.showIo[io].show === true) return io;})
                .filter(x => x);
            if (this.events) newEvents = this.events.map((ev) => {if (whichIos.includes(ev.io_id)) return ev}).filter(y => y);
            const oldL = this.newEvents.length;
            this.newEvents.splice(0, oldL, ...newEvents);
        }
    }
}
</script>
<style>
div .v-calendar-daily__pane {
    overflow: hidden !important;
}
div .v-calendar-daily__scroll-area {
    overflow: hidden !important;
}
</style>
<style scoped>
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}
.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}
.v-event-delete {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6px;
    height: 12px;
}


</style>
