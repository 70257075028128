/**
 * Takes an array of strings and returns a string representation of the array.
 * @param array1 the array of strings.
 * @returns {string} The string representation
 */
const arrayToString = function(array1) {
    let myString = "";
    if (!array1) return myString;
    array1.forEach((item) => {
        myString += item + ", ";
    });
    myString = myString.substring(0, myString.length - 2);
    return myString;
};

const colorOptions = [
    { text: 'Indigo', value: 'indigo' },
    { text: 'Light Blue', value: 'light-blue' },
    { text: 'Purple', value: 'purple' },
    { text: 'Light Green', value: 'light-green' },
    { text: 'Deep Orange', value: 'deep-orange' },
    { text: 'Amber', value: 'amber' },
    { text: 'Primary', value: 'primary' },
    { text: 'Secondary', value: 'secondary' },
    { text: 'Accent', value: 'accent' },
    { text: 'Red', value: 'red' },
    { text: 'Pink', value: 'pink' },
    { text: 'Deep Purple', value: 'deep-purple' },
    { text: 'Blue', value: 'blue' },
    { text: 'Cyan', value: 'cyan' },
    { text: 'Teal', value: 'teal' },
    { text: 'Green', value: 'green' },
    { text: 'Lime', value: 'lime' },
    { text: 'Yellow', value: 'yellow' },
    { text: 'Orange', value: 'orange' },
    { text: 'Brown', value: 'brown' },
    { text: 'Blue Gray', value: 'blue-gray' },
    { text: 'Gray', value: 'grey' },
    { text: 'Black', value: 'black' },
];
const colors = colorOptions.map((el) => el.value);

export { arrayToString, colors, colorOptions};
