<template>
    <div>
        <v-sheet height="64" width="90%" v-if="withHeader">
            <v-toolbar width="100%" floating flat>

                <v-btn
                        class="mr-xl-10 mr-lg-6 mr-md-2"
                        color="grey darken-2"
                        outlined
                        @click="setCurrentDate"
                >{{ $t('schedule.today') }}</v-btn>
                <v-btn
                        color="grey darken-2"
                        class="mx-xl-10 mx-lg-6 mx-md-2"
                        fab
                        small
                        text
                        @click="prev"
                >
                    <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="toolbar-title-width">
                    <v-toolbar-title
                            class="mx-lg-6 mx-2"
                    >
                        {{ titleCal }}
                    </v-toolbar-title>
                </div>
                <v-btn
                        color="grey darken-2"
                        class="mx-xl-10 mx-lg-6 mx-md-2"
                        fab
                        small
                        text
                        @click="next"
                >
                    <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
            </v-toolbar>
        </v-sheet>
        <v-sheet height="400" width="100%">
            <v-calendar
                    ref="monthCalendar1"
                    :key="currentDate"
                    v-model="currentDate"
                    :weekdays="weekdays"
                    :now="computedDate"
                    color="accent"
                    show-week
                    @click:date="sendChangedDate"
                    :locale="$i18n.locale"
                    v-if="withHeader"
            >
                <template v-slot:day="{ date }">
                    <v-row>
                        <template v-if="trackedData[date]">
                            <v-sheet
                                :key="trackedData[date].name"
                                class="tracked"
                                :title="trackedData[date].name"
                                :color="trackedData[date].color"
                                :width="`${trackedData[date].fill}%`"
                                dark
                            ></v-sheet>
                            <v-sheet
                                    v-if="Object.keys(trackedData[date]).includes('SPECIAL')"
                                    :key="trackedData[date].SPECIAL.name"
                                    class="tracked special"
                                    :title="trackedData[date].SPECIAL.name"
                                    color="red"
                                    :width="`${trackedData[date].fill}%`"
                                    dark
                            ></v-sheet>
                        </template>
                    </v-row>
                </template>
            </v-calendar>
            <v-calendar
                    ref="monthCalendar1"
                    :value="currentDate"
                    :weekdays="weekdays"
                    color="transparent"
                    show-week
                    @click:date="sendChangedDate"
                    :locale="$i18n.locale"
                    v-else
            >
                <template v-slot:day="{ date }">
                    <v-row>
                        <template v-if="trackedData[date]">
                            <v-sheet
                                    class="tracked"
                                    :title="trackedData[date].name"
                                    :color="trackedData[date].color"
                                    :width="`${trackedData[date].fill}%`"
                                    dark
                            ></v-sheet>
                            <v-sheet
                                    v-if="Object.keys(trackedData[date]).includes('SPECIAL')"
                                    :key="trackedData[date].SPECIAL.name"
                                    class="tracked special"
                                    :title="trackedData[date].SPECIAL.name"
                                    color="red"
                                    :width="`${trackedData[date].fill}%`"
                                    dark
                            ></v-sheet>
                        </template>
                    </v-row>
                </template>
            </v-calendar>
        </v-sheet>
    </div>
</template>

<script>
import {dateConv, getWeekNumber, timeConv} from "@/services/dateConverter";
import SunCalc from "suncalc";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
    name: "MonthCalendar",
    props: {
        today: {
            type: String,
            required: true,
            default: '1970-01-01'
        },
        withHeader: {
            type: Boolean,
            required: true,
            default: false
        },
        trackedData: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    emits: ['date-changed'],
    mounted(){
        this.$refs.monthCalendar1.checkChange();
        this.$nextTick(() => {
            this.currentDate = this.today;
            if(this.withHeader) this.$store.dispatch('sched/setSunCalc', this.today);
        });
    },
    data() {
        return {
            loggedin: false,
            errored: false,
            time: '',
            weekNr: '',
            currentDate: '',
            month: '',
            tracked: {},
            category: ['out1', 'out2'],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            weekInfo: null,
            nodesList: [],
            suncalc: {
                sunrise: '0:00',
                sunset: '0:00',
                toggle: false,
            },
            location: {
                lat: 51.4716833,
                lng: 5.6705665,
                type: 'fixed'
            },
            calendarTitle: '',
        }
    },
    computed: {
        ...mapGetters({getGpsLocation: "sched/getGpsLocation"}),
        weekCal(){
            return getWeekNumber(new Date(this.today));
        },
        realToday(){
            return new Date().toISOString().split('T')[0];
        },
        titleCal() {
            if (this.currentDate){
                const day = new Date(this.currentDate ? this.currentDate : '');
                const options = { month: 'long' };
                let locale;
                if (this.$i18n.locale === 'en'){
                    locale = 'en-US'
                } else {
                    locale = 'nl-NL';
                }
                const month = new Intl.DateTimeFormat(locale, options).format(day);
                const monthPretty = month.charAt(0).toUpperCase() + month.slice(1);

                return monthPretty + " " + day.getFullYear();
            } else return '';

        },
        getSunrise(){
            return this.suncalc.sunrise;
        },
        getSunset(){
            return this.suncalc.sunset;
        },
        calendarWidth() {
            const width = this.$vuetify.breakpoint.width;
            const vtf = this.$vuetify.breakpoint.name;
            return (width + 150) / 5.4;
        },
        calendarHeight() {
            return null;
        },
        computedDate() {
            return this.currentDate ? this.currentDate : undefined;
        }
    },
    methods: {
        prev() {
            this.$refs.monthCalendar1.prev();
            const date  = dayjs(this.currentDate);
            const dateStr = date.format('YYYY-MM-DD');
            const dateObj = {
                date: dateStr
            }
            this.sendChangedDate(dateObj)
        },
        next() {
            this.$refs.monthCalendar1.next();
            const date  = dayjs(this.currentDate);
            const dateStr = date.format('YYYY-MM-DD');
            const dateObj = {
                date: dateStr
            }
            this.sendChangedDate(dateObj)
        },
        showSunriseset() {
            this.suncalc.toggle = !this.suncalc.toggle;
        },
        setCurrentDate(){
            this.currentDate = this.realToday;
            this.$store.dispatch('sched/setSunCalc', this.currentDate);
            this.$emit('date-changed', this.currentDate);
        },
        sendChangedDate(date = null){
            this.$store.dispatch('sched/setSunCalc', date.date);
            this.$emit('date-changed', date.date);
        }
    }
}
</script>
<style>
.v-calendar-weekly__day {
    padding: 0 0 0 5px !important;

}
</style>
<style lang="scss" scoped>
.toolbar-title-width {
  width: 16rem;
  padding: 16px;
}

div .v-calendar .v-event {
  color: purple !important;

}
.tracked {
  display: block;
  height: 10px;
  max-height: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.6;
}

</style>
