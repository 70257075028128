<template>
    <div id="getschedule">
        <div>
            <div class="d-flex justify-space-between accent" style="width: 100%">
                <v-card class="pa-2 text-center text-h4 my-white-text" color="accent" tile width="700" elevation="0">
                    {{ $t('menu.buttons.schedules') }}
                </v-card>

                <v-card tile :rounded="false" elevation="0" width="1050">
                    <v-tabs
                        v-model="tab"
                        background-color="accent"
                        dark
                        next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline"
                        fixed-tabs
                        center-active
                        height="56"
                        slider-color="#edabae"
                        slider-size="5"
                    >
                        <v-tab v-show="tab.id !== 3" v-for="tab in tabs" :key="tab.id" :to="{name: tab.route }">
                            {{ $t(tab.i18nLink) }}
                        </v-tab>
                    </v-tabs>
                </v-card>

            </div>
            <div>
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Schedule",
    props: {
        today: {
            type: String,
            required: false
        },
    },
    mounted(){
        this.searchLocation();
        this.$store.dispatch('sched/setGpsLocation', this.location);

    },
    data() {
        return {
            tab: '/schedule/overview',
            tabs: [
                {
                    id: 0,
                    name: 'info',
                    route: 'schedInfo',
                    i18nLink: 'menu.buttons.schedules'
                },
                {
                    id: 1,
                    name: 'calendar',
                    route: 'threeMonthView',
                    i18nLink: 'schedule.calendar',
                },
                {
                    id: 2,
                    name: 'dusk',
                    route: 'schedDusk',
                    i18nLink: 'schedule.duskTimes'
                },
            ],
            location: {
                lat: -1,
                lng: -1
            }
        };
    },
    computed: {
    },
    methods: {
        setActiveTab(tabNr){
            this.tab = tabNr;
        },
        searchLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.location.lat = position.coords.latitude;
                    this.location.lng = position.coords.longitude;
                })
                this.$store.dispatch('sched/setGpsLocation', this.location);
            }
        }
    },
    watch: {
        location: {
            handler: function(){
                this.$store.dispatch('sched/setGpsLocation', this.location);
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.my-white-text {
    color: #ffffff;
    font-weight: bold;
}
</style>
