<template>
    <div>
        <v-row>
            <v-col cols="6">
                <div class="calendarCard mx-4 my-2">
                    <MonthCalendar :withHeader="true" :today="currentDate" :trackedData="firstMonthTracked" @date-changed="setNewDate" :key="currentDate"/>
                </div>
                <div class="calendarCard mx-4 my-2">
                    <MonthCalendar :withHeader="false" :today="currentDatePlus1Month" :trackedData="secondMonthTracked" @date-changed="setNewDate" :key="currentDatePlus1Month"/>
                </div>
                <div class="calendarCard mx-4 my-2">
                    <MonthCalendar :withHeader="false" :today="currentDatePlus2Months" :trackedData="thirdMonthTracked" @date-changed="setNewDate" :key="currentDatePlus2Months"/>
                </div>
            </v-col>
            <v-col cols="6" class="text-center">
                <div class="mb-6">
                    <WeekCalendar :today="currentDate" :scheduleProfile="singleSchedule" :events="weekEvents" :key="currentDate" @openDayView="openDayViewOnDay" />
                </div>
                <div class="mt-8" v-if="showDay">
                    <DayCalendar :today="currentDate" :events="dayEvents" @closeDayView="showDay = false"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MonthCalendar from "@/components/Schedule/MonthCalendar.vue";
import dayjs from "dayjs";
const isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);
import {mapGetters} from "vuex";
import WeekCalendar from "@/components/Schedule/WeekCalendar.vue";
import DayCalendar from "@/components/Schedule/DayCalendar.vue";
import {isDateInRange, timeConv, timeFormat} from "@/services/dateConverter";
import {arrayToString, colors} from "@/services/mainHelpers";

const dayjsOfWeek = {0: "sun", 1: "mon", 2: "tue", 3: "wed", 4: "thu", 5: "fri", 6: "sat"};

export default {
    name: "ThreeMonthView",
    components: {DayCalendar, WeekCalendar, MonthCalendar},
    props: {
        today: {
            type: String,
            required: false
        },
        // scheduleProfile: {
        //     type: Object,
        //     required: false,
        //     default: null
        // }
    },
    data(){
        return {
            dayjsDate: null,
            currentSchedule: null,
            tracked: {0: {},1: {}, 2: {}}, // for three months
            dayEvents: [],
            weekEvents: [],
            thisWeek: {},
            showDay: false,
        };
    },
    mounted(){
        this.init();
    },
    computed: {
        ...mapGetters({getGpsLocation: 'sched/getGpsLocation', singleSchedule: 'sched/getSingleSchedule',
         sunriseset: 'sched/getSunCalc'}),
        currentDate() {
            return this.dayjsDate ? this.dayjsDate.format(this.localeStr) : '';
        },
        firstMonthTracked(){
            return this.tracked[0];
        },
        secondMonthTracked(){
            return this.tracked[1];
        },
        thirdMonthTracked(){
            return this.tracked[2];
        },
        currentDatePlus1Month() {
            return this.dayjsDate ? this.dayjsDate.add(1, 'month').format(this.localeStr) : '';
        },
        currentDatePlus2Months() {
            return this.dayjsDate ?this.dayjsDate.add(2, 'month').format(this.localeStr) : '';
        },
        localeStr(){
            return this.$i18n.locale === 'en' ? "YYYY-MM-DD" : "DD-MM-YYYY";
        }
    },
    methods: {
        init(){
            const today = this.today ? this.today : new Date().toISOString().split('T')[0];
            this.dayjsDate = dayjs(today, this.localeStr)
            this.$store.dispatch('sched/setSunCalc', this.dayjsDate, this.getGpsLocation);
            if(Object.keys(this.$route.query).length > 0){
                this.$store.dispatch('sched/searchSchedule', {
                    schedule_id: this.$route.query.schedule_id,
                    schedule_version: this.$route.query.schedule_version
                });
                this.getTrackedFromSchedule();
                this.getEventsFromScheduleWeek();
            }
        },
        getTranslatedweekDays(weekArray){
            let tempArr = [];
            weekArray.forEach((day) => {
                tempArr.push(this.$t(`schedule.weekdays.${day}`));
            });
            return tempArr;
        },
        setNewDate(date) {
            this.dayjsDate = dayjs(date, this.localeStr);
            this.$store.dispatch('sched/setSunCalc', this.dayjsDate, this.getGpsLocation);
            this.getTrackedFromSchedule();
            this.getEventsFromScheduleWeek();
            this.showDay = true;
        },
        getEventsFromScheduleWeek() {
            const workingDate = this.dayjsDate;
            const begin = workingDate.startOf('isoWeek');
            this.thisWeek.start = begin.add(-1, 'day');
            this.thisWeek.end = dayjs(`${begin.add(6, 'day').format('YYYY-MM-DD')}T23:59:59`);
            const locale = this.$i18n.locale;

            let timedDate = false;
            let startDateObj, endDateObj;
            const dateFormatStr = "YYYY-MM-DD HH:mm:ss"
            const dateFormatShort = "YYYY-MM-DD"
            const timeFormatStr = "HH:mm";

            let weekStart = this.thisWeek.start.clone();
            const weekEnd = this.thisWeek.end.clone();

            this.weekEvents = [];

            while (weekStart.isBefore(weekEnd)) {

                const sunrise = this.sunriseset.sunrise + ":00";
                const sunset = this.sunriseset.sunset + ":00";
                const startDate =  weekStart.format('YYYY-MM-DD');

                for (let io of this.singleSchedule.schedules) {
                    // make sure we can still find the io names after the API changed
                    // const io_id = Object.keys(this.showIo).find(key => this.showIo[key].external === io.io_id)
                    let profile_id = 0;
                    for (let profile of io.profiles) {

                        if (profile.startDate) {
                            timedDate = true;
                            const thisYear =  weekStart.format('YYYY');
                            const startDateStrings = profile.startDate.split("-");
                            const endDateStrings = profile.endDate.split("-");
                            if (startDateStrings.length === 3) {
                                let year;
                                if(parseInt(startDateStrings[0]) < thisYear) year = thisYear;
                                else year = thisYear + 1;
                                startDateObj = dayjs(`${thisYear}-${profile.startDate} 12:00:00`, dateFormatStr);
                            } else {
                                startDateObj = dayjs(`${thisYear}-${profile.startDate} 12:00:00`, dateFormatStr);
                            }
                            console.log("start date object : ", startDateObj)
                            if (endDateStrings.length === 3) {
                                let year;
                                if (thisYear < parseInt(endDateStrings[0])) year = thisYear;
                                else year = thisYear - 1;
                                endDateObj = dayjs(`${year}-${endDateStrings[1]}-${endDateStrings[2]} 12:00:00`, dateFormatStr);
                            } else {
                                endDateObj = dayjs(`${thisYear}-${endDateStrings[0]}-${endDateStrings[1]} 12:00:00`, dateFormatStr);
                            }
                            console.log("end date object : ", endDateObj)

                        }
                        let switch_id = 0;
                        for (let switch1 of profile.switches) {

                            let startTime;
                            let startTimeObj;
                            let startString;
                            if (switch1.on.kind === 'Absolute') {
                                startTime = switch1.on.time;
                                // startString = `${startTime.substr(0,5)} (ABS)`;
                                startString = timeFormat(startTime, switch1.on.kind, locale);
                                startTimeObj = dayjs(`${startDate} ${startTime}`, dateFormatStr)
                            } else if (switch1.on.kind === 'Sunrise') {
                                const tmp = switch1.on.offset;
                                startString = timeFormat(tmp, switch1.on.kind, locale);
                                // startString = `${tmp} (RIS)`;
                                startTimeObj = dayjs(`${startDate} ${sunrise}`, dateFormatStr).add(switch1.on.offset, 'second');
                                startTime = startTimeObj.format(timeFormatStr);
                            } else if (switch1.on.kind === 'Sunset') {
                                const tmp = switch1.on.offset;
                                startString = timeFormat(tmp, switch1.on.kind, locale);
                                // startString = `${tmp} (SET)`;
                                startTimeObj = dayjs(`${startDate} ${sunset}`, dateFormatStr).add(switch1.on.offset, 'second');
                                startTime = startTimeObj.format(timeFormatStr);
                            }

                            let currentDay = dayjs(`${startDate} ${startTime}`, dateFormatStr);
                            if (currentDay.isBefore(dayjs(`${startDate} 12:00:00`, dateFormatStr))) {
                                currentDay = currentDay.add(-1, 'day');
                            }

                            let switchEndTime;
                            let endString;
                            if (switch1.off.kind === 'Absolute') {
                                const tmp = switch1.off.time;
                                //endString = `${tmp.substr(0,5)} (ABS)`;
                                endString = timeFormat(tmp, switch1.off.kind, locale);
                                switchEndTime = switch1.off.time;
                            } else if (switch1.off.kind === 'Sunrise') {
                                const tmp = switch1.off.offset;
                                endString = timeFormat(tmp, switch1.off.kind, locale);
                                // endString = `${tmp} (RIS)`;
                                switchEndTime = dayjs(`${startDate} ${sunrise}`, dateFormatStr).add(switch1.off.offset, 'second').format(timeFormatStr);
                            } else if (switch1.off.kind === 'Sunset') {
                                const tmp = switch1.off.offset;
                                endString = timeFormat(tmp, switch1.off.kind, locale);
                                // endString = `${tmp} (SET)`;
                                switchEndTime = dayjs(`${startDate} ${sunset}`, dateFormatStr).add(switch1.off.offset, 'second').format(timeFormatStr);
                            }

                            const endTime = dayjs(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss').isBefore(dayjs(`${startDate} ${switchEndTime}`, dateFormatStr)) ?
                                dayjs(`${startDate} ${switchEndTime}`, dateFormatStr) :
                                dayjs(`${startDate} ${switchEndTime}`, dateFormatStr).add(1, 'day');

                            let event = {
                                name: this.singleSchedule.name,
                                day: currentDay.format('ddd').toLowerCase(),
                                schedule_uid: this.singleSchedule.uid,
                                switch_id: switch_id + 1,
                                io_id: io.io_id,
                                profile_id: profile_id + 1,
                                start: startTimeObj.format(dateFormatStr),
                                end: endTime.format(dateFormatStr),
                                color: colors[io.io_id.split(".")[1] - 1],
                                display: {
                                    onStr: startString,
                                    offStr: endString,
                                    days: arrayToString(this.getTranslatedweekDays(profile.days)), // arrayToString(profile.days),
                                    startDate: profile.startDate ? profile.startDate : '',
                                    endDate: profile.endDate ? profile.endDate : '',
                                }
                            };

                            if (profile.days.includes(event.day)) {
                                if (!timedDate || (timedDate && (startTimeObj.isAfter(startDateObj) && startTimeObj.isBefore(endDateObj)))) {
                                    this.weekEvents.push(event);
                                }

                            }
                            switch_id++;
                        }
                        timedDate = false;
                        profile_id++;
                    }
                }

                weekStart = dayjs(weekStart).clone().add(1, 'day');
            }
            this.getEventsFromScheduleDay();
        },
        getEventsFromScheduleDay(){
            const calcedDay = `${this.dayjsDate.year()}-${this.dayjsDate.month() + 1}-${this.dayjsDate.date().toString().padStart(2, '0')}`;
            console.log("searching for date : ", calcedDay);
            const dayEvents = this.weekEvents.map((ev) => {
                if (ev.start.startsWith(calcedDay) || ev.end.startsWith(calcedDay)) {
                    console.log("This sshould add a day event: ", ev);
                    return {
                        ...ev,
                        category: ev.io_id
                    };
                }
            }).filter(x => x);
            console.log("DayEvents : ", dayEvents);
            const oldL = this.dayEvents.length;
            this.dayEvents.splice(0, oldL, ...dayEvents);
        },
        getTrackedFromSchedule(){
            // We want to retrieve the tracked times from the schedule and send these to the MONTH calendars
            // standard :
            const ios = this.singleSchedule.schedules;
            let schedules = [];
            // the standard schedule is the first schedule in the array. runs on ALL days and DOES NOT have a date!
            // if the profile has 1 array .. it is has one switch for all of the days (STANDARD)
            // if the profile has 2 or more arrays, it is switching for different days. (EXCEPTION)
            for (let i = 0; i < ios.length; i++){
                let io = {
                    io_id: -1,
                    io_name: '',
                    STANDARD: '',
                    EXCEPTION: '',
                    DAYSPECIFIC: []
                }
                const standard1 = ios[i].profiles.map((prof) => {if (!prof.startDate) return prof;}).filter(x => x);
                const dayspec = ios[i].profiles.map((prf) => {if (prf.startDate) return prf;}).filter(x => x);

                io.io_id = i;
                io.io_name = ios[i].io_id;

                if (standard1.length === 1){
                    // STANDARD ON/OFF SCHEDULE (mostly sunset to sunrise)
                    if (standard1[0].switches[0].on.kind === 'Sunset' && standard1[0].switches[0].off.kind === 'Sunrise'){
                        // this is a standard schedule
                        io.STANDARD = "Sunset/Sunrise STANDARD"
                    } else io.STANDARD = "UNKNOWN STANDARD"
                } else {
                    // EXCEPTION for certain days (weekend or fri+sat)
                    if (standard1[0].days.length === 2 || standard1[1].days.length === 2){
                        if(standard1[0].days.length === 2){
                            io.EXCEPTION = standard1[0].days.join('');
                        } else io.EXCEPTION = standard1[1].days.join('');
                    } else if (standard1[0].days.length === 3 || standard1[1].days.length === 3){
                        if(standard1[0].days.length === 3){
                            io.EXCEPTION = standard1[0].days.join('');
                        } else io.EXCEPTION = standard1[1].days.join('');
                    } else {
                        // other EXCEPTION
                        io.EXCEPTION = "UNKNOWN EXCEPTION";
                    }
                }
                io.DAYSPECIFIC = [...dayspec];
                schedules.push(io);
            }

            // make trackedData for 3 months
            // color is always blue and fill is always 100%
            // tracked has a tracked object for each date of THAT month :
            // '2024-03-01': {
            //                  name: 'Standard + frisat',
            //                  color: 'blue',
            //                  fill: 100
            //                }
            const daysInMonth1 = this.dayjsDate.daysInMonth();
            const daysInMonth2 = this.dayjsDate.add(1, 'month').daysInMonth();
            const daysInMonth3 = this.dayjsDate.add(2, 'month').daysInMonth();
            let tempTracked = {0: {}, 1: {}, 2: {}};
            Object.assign(this.tracked, {0: {}, 1: {}, 2: {}});
            const days = [daysInMonth1, daysInMonth2, daysInMonth3];

            for (let m = 0; m < days.length; m++) {
                for (let n = 1; n < days[m] + 1; n++) {
                    const [year, month, day] = [this.dayjsDate.get('year'), this.dayjsDate.get('month') + 1 + m, n];
                    const date = dayjs(`${year}-${month}-${day}`);
                    const dateStr = date.format("YYYY-MM-DD");
                    tempTracked[m][dateStr] = {
                        name: '',
                        ios: [...schedules],
                        color: 'red',
                        fill: 100,
                        type: ''
                    };
                    for (let io = 0; io < schedules.length; io++) {
                        if (schedules[io].DAYSPECIFIC && schedules[io].DAYSPECIFIC.length > 0) {
                            for (let ds = 0; ds < schedules[io].DAYSPECIFIC.length; ds++) {

                                if (isDateInRange(dateStr, schedules[io].DAYSPECIFIC[ds].startDate,
                                    schedules[io].DAYSPECIFIC[ds].endDate) && schedules[io].DAYSPECIFIC[ds].days.includes(dayjsOfWeek[date.day()])) {
                                    tempTracked[m][dateStr].name = `DAYSPEC for ${schedules[io].io_name}`;
                                    tempTracked[m][dateStr].sched = schedules[io].DAYSPECIFIC[ds];
                                    tempTracked[m][dateStr].type = 'SPECIAL';
                                }
                            }
                        }
                    }

                    if (tempTracked[m][dateStr].type === 'SPECIAL') {
                        this.tracked[m][dateStr] = {
                            name: 'Standard',
                            ios: [...schedules],
                            color: 'blue',
                            fill: 100,
                            SPECIAL: {
                                name: tempTracked[m][dateStr].name,
                                sched: tempTracked[m][dateStr].sched,
                                type: tempTracked[m][dateStr].type,
                            }
                        };
                    } else {
                        this.tracked[m][dateStr] = {
                            name: 'Standard',
                            ios: [...schedules],
                            color: 'blue',
                            fill: 100,
                        };
                    }

                }

                }

        },
        openDayViewOnDay(data){
            console.log("Event in openDayViewOnDay", data);
            this.dayjsDate = dayjs(data.day, 'YYYY-MM-DD');
            this.getEventsFromScheduleDay();
            this.showDay = true;
        }
    }
}
</script>

<style scoped>
.calendarCard {
    border: 1px solid black;
}
</style>
