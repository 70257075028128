<template>
    <div v-if="isAuthenticated">
        <router-view />
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Calendar",
    methods: {

    },
    computed: {
        ...mapGetters({isAuthenticated: 'auth/isAuthenticated'}),
    }
}
</script>

