<template>
    <div>
        <v-card>
            <div class="d-flex flex-row justify-space-between">
                <v-card-title>{{ today }}</v-card-title>
                <v-icon class="me-6" color="red" large @click="$emit('closeDayView', true)">mdi-close</v-icon>
            </div>
            <div>
                <v-row>
                    <v-col>Schedule</v-col>
                    <v-col>{{ currentSchedule.name }}</v-col>
                </v-row>
            </div>
            <div>
                <v-sheet heigh="400">
                    <v-calendar
                        ref="dayCalendar"
                        v-model="today"
                        color="accent"
                        type="category"
                        category-show-all
                        interval-height="20"
                        :categories="ios"
                        :events="events"
                        :locale="$i18n.locale"
                        overflow="hidden"
                    >
                        <template v-slot:event="{ event }">
                            <div>
                                <div class="text-center"><strong>{{ event.name }}</strong></div>
                                <div class="text-center" v-if="eventLength(event) > 2">IO : {{ event.io_id }}</div>
                            </div>
                        </template>
                        <template v-if="true" v-slot:day-body="{ timeToY }">

                            <div
                                    :style="{ top: timeToY(sunriseset.sunrise ? sunriseset.sunrise : '0:00') + 'px' }"
                                    class="v-current-time"
                            ></div>

                            <div

                                    :style="{ top: timeToY(sunriseset.sunset ? sunriseset.sunset : '23.59') + 'px'}"
                                    class="v-current-time"
                            ></div>
                        </template>
                    </v-calendar>
                </v-sheet>

            </div>
        </v-card>
        <div>
            <v-card>
                <v-card-title class="mx-auto">NODES</v-card-title>
                <v-card-text>
                   <div>
                       <v-row cols="12">
                           <div v-for="node in nodes" :key="node">
                               <v-btn cols="4">{{ node }}</v-btn>
                           </div>
                       </v-row>


                   </div>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DayCalendar",
    props: {
        today: {
            type: String,
            required: true,
            default: '1970-01-01'
        },
        scheduleProfile: {
            type: Object,
            required: false,
            default: () => {}
        },
        events: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    mounted(){
        this.init();
    },
    data() {
        return {
            ios: ['out.1', 'out.2'],
            nodes: []
        };
    },
    computed: {
        ...mapGetters({currentSchedule: 'sched/getSingleSchedule', sunriseset: 'sched/getSunCalc',
            nodesWithSchedule: 'sched/nodesWithSchedule'})
    },
    methods: {
        ...mapActions({getNodesWithSchedule: 'sched/getNodesWithSchedule'}),
        init() {
            const schedName = this.currentSchedule.name;
            const re = /^(\w\d+\w\d+)(\w+)?-(.*)$/g;
            const names = re.exec(schedName);
            console.log("names : ", names);
            this.today2 = this.today;
            this.getNodesWithSchedule({uid: this.currentSchedule.uid})
                .then(() => console.log(this.nodesWithSchedule));
        },
        eventLength(event){
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            const length = (endDate - startDate) / (1000 * 60 * 60); // length in hours
            return Math.floor(length);
        },
    }
}
</script>

<style>
div .v-calendar-category {
    overflow: hidden !important;
}
</style>
<style scoped>
.node-list {
    list-style: none;
}
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}
</style>
